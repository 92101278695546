.user-update{
    .modal-container{
        @include grid_center_center;
        left: 32%;
        top: 10%;
        height: auto;
        grid-template-rows: 30% 70% !important;
        min-height: $base * 70;

        .modal-header{
            @include flex_center_center;
            width: $base * 90;
            gap: $gap_md;

            .avatar {
                @include img_lg;

                .hover-text {
                    display: none;
                }

                label{
                    @include img_lg;
                    position: absolute;
                    cursor: pointer;

                    &:hover .hover-text{
                        @include img_lg;
                        @include flex_center_center;
                        position: relative;
                        top: -105%;
                        text-align: center;
                        background-color: #333;
                        color: $white;
                        opacity: 0.7;
                    }
                }

                img {
                    @include img_lg;
                    position: relative;
                }

                input{
                    display: none;
                }
            }
        }

        .modal-content{
            @include flex_center;
            flex-direction: column;
            gap: $gap_lg;
            height: auto;
            padding: 0 $gap_md;
            margin-bottom: $gap_md;
            
            .inputs{
                margin-bottom: $gap_md;
        
                .modal-input{
                    @include flex_center;
                    height: $base * 8;
                    justify-content: space-between;

                    svg {
                        position: absolute;
                        top: 35%;
                        left: 0;
                        transform: translateY(-$border_round);
                        color: #999;
                        font-size: $gap_md * 1.5;
                        transition: all 0.2s ease;
                    }
                    .password-icon{
                        left: 65%;
                    }
                    
                }
                .not-show::after{
                    position: absolute;
                    content: '';
                    height: calc($base / 2.5);
                    background-color: $button_color;
                    width: 70%;
                    top: 100%;
                    left: 0;
                }
            }
            .delete{
                @include flex_center;
                width: 100%;
                span{
                    border: none;
                    color: $main_color;
                    font-style: italic;
                    cursor: pointer;
                }
            }
        }
    }
    .smaller{
        grid-template-rows: 20% 80%;

        .close-button-wrapper{
            top: 1%;
        }
    }
}