@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

@import './variables';
@import './mixins';

@import './pages/chat';
@import './pages/authorization';

@import './components/aux/submit';
@import './components/aux/user-bage';
@import './components/user/info';
@import './components/user/update';
@import './components/welcome';
@import './components/contacts';
@import './components/update-group';
@import './components/create-group';
@import './components/messages';
@import './components/chat-input';
@import './components/chat-container';


.Toastify__toast-theme--dark {
    background-color: $modal_bg !important;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    @include flex_center_center;
    min-height: 100vh;
    background: $gradient;
    overflow: hidden;
    font-size: $gap_md;
    overflow-wrap: break-word;
    font-family: Arial, Helvetica, sans-serif;
}

@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}
  .loading-spinner {
    width: calc($base * 10);
    height: calc($base * 10);
    border: $base solid $selection_color;
    border-top: $base solid $main_color;
    will-change: transform;
    border-radius: $border_round;
    animation: spinner 1s linear infinite;
  
}

.hidden-input,
.hidden-input label,
.hidden-input label:hover .hover-text{
    height: calc($base * 6);
    width: calc($base * 6);
}

.hidden-input {
    .hover-text, input {
        display: none;
    }

    label {
        position: absolute;
        cursor: pointer;

        &:hover .hover-text{
            @include flex_center_center;
            position: relative;
            top: -105%;
            text-align: center;
            background-color: $text_secondary_color;
            color: $white;
            opacity: 0.7;
        }
    }

    svg {
        height: calc($base * 5);
        width: calc($base * 5);
        position: relative;
    }
}

.chat-loading{
    @include flex_center_center;
    height: calc($gap_lg * 15);
}

.form-container{
  font-family: 'Poppins', sans-serif;
}

.center{
    @include flex_center_center;

    svg{
        margin-left: $gap_sm;
        cursor: pointer;
    }
    .modal-input{
        position: relative;
        margin-top: $gap_lg;
        height: calc($base * 8);
        text-align: center;

        & + .modal-input{
            margin-top: $gap_lg;
        }

        input {
            @include no_bd_no_bg;
            height: 90%;
            width: 60%;
            outline: none;
            padding: 0 calc($base * 7);
            font-size: $gap_md;
            border-bottom: calc($base / 2.5) solid $border_color_light;
            transition: all 0.2s ease;

            &:focus{
                border-bottom-color: $main_color;

                & ~ svg{
                    color: $main_color;
                }
            }
        }
        .active{
            border-bottom-color: $main_color !important;
            & ~ svg{
                color: $main_color !important;
            }
        }

        svg {
            position: absolute;
            top: 35%;
            left: 5%;
            transform: translateY(-$border_round);
            color: $text_color;
            font-size: calc($gap_md * 1.5);
            transition: all 0.2s ease;
        }

        .button-submit{
            cursor: pointer;
            padding: $gap_sm $gap_md;
            margin-left: $gap_md;
            border: none;
            border-radius: $base;
            background-color: $button_color;
            color: $white;
        }
    }
}

.width-100{
  width: 100%;
}

.modal-active{
  width: 100vw;
  height: 100vh;
  background-color: $modal_bg !important;
}

.none {
  display: none;
}

.button{
    cursor: pointer;
    width: 100%;
    margin-top: calc($base * 7);
    font-size: $gap_md;
    font-weight: 500;
    letter-spacing: calc($base / 5);
    border: none;
    border-radius: $base;
    background-color: $button_color;
    color: $white;
    transition: all 0.3s ease;

    &:hover{
        filter: brightness(1.1);
    }
}

.contacts {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;

    .contact {
        position: relative;
        display: grid;
        grid-template-columns: 20% 80%;
        align-items: stretch;
        width: 100%;
        padding: $gap_md;
        cursor: pointer;
        color: $text_secondary_color;
        transition: 0.5s ease-in-out;

        &::after{
            position: absolute;
            content: '';
            top: 100%;
            left: 22%;
            height: calc($base / 5);
            width: 76%;
            background-color: $selection_color;
        }
    
        &:hover{
            background: $selection_color;
        }
        
        .avatar {
            img {
                @include img_md;
                max-inline-size: 100%;
            }
        }
    }
}

.chat-menu-toggle{
    position: absolute;
    display: inline-block;    
    top: 9%;
    min-width: calc($base * 30);
    min-height: calc($base * 4);
    cursor: pointer;
    z-index: 2;
    padding: calc($base * 2) 0;
    background: $white;
    border-radius: $base;
    transition: display 0.8s ease;

    .list-item{
        @include flex_center;
        @include no_bd_no_bg;
        width: 100%;
        padding: calc($base * 2);

        &:hover{
            background-color: $selection_color;
        }
        svg{
            margin-right: $gap_md;
            font-size: $gap_md;
            color: $text_secondary_color;
        }
        span{
            color: $text_secondary_color;
            font-size: calc($base * 2.8);
        }
    }
}

.close-button-wrapper{
    position: absolute;
    top: 2%;
    left: 92%;

    .close-button{
        @include no_bd_no_bg;
        font-size: calc($gap_md * 1.7);
    }
}

.modal-wrapper{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 4;
    background-color: rgba(0, 0, 0, 0.4);

    .modal-container{
        position: relative;
        display: grid;
        justify-content: center;
        grid-template-rows: 10% 90%;
        width: calc($base * 100);
        padding: calc($gap_md * 1.8) $gap_lg;
        background-color: $white;
        border-radius: calc($base * 2);
        box-shadow: 0 calc($base * 4) calc($base * 4) rgba(0, 0, 0, 0.1);

        .modal-header{
            width: calc($base * 90);
        }

        .modal-content{
            width: -webkit-fill-available;

            .inputs{
                width: 100%;

                .modal-input{
                    position: relative;
                    margin-top: $gap_lg;
                    height: calc($base * 8);
                    width: 100%;
                    
                    & + .modal-input{
                        margin-top: $gap_lg;
                    }

                    input {
                        @include no_bd_no_bg;
                        height: 90%;
                        width: 70%;
                        padding: 0 calc($base * 7);
                        outline: none;
                        font-size: $gap_md;
                        border-bottom: calc($base / 2.5) solid $border_color_light;
                        transition: all 0.2s ease;
            
                        &[type="file"]{
                            height: 90%;
                            color: $text_color;
                            padding-top: 1.5%;
                            border-bottom-color: $border_color_light;
            
                            &::-webkit-file-upload-button{
                                display:none;
                            }
                            &:focus{
                                border-bottom-color: $main_color;
                            }
                            & ~ svg{
                                color: $text_color;
                            }
                        }

                        &:focus{
                            border-bottom-color: $main_color;
                            & ~ svg{
                                color: $main_color;
                            }
                        }
                        &:not(:placeholder-shown){
                            &:not([type="file" i]){
                                border-bottom-color: $main_color;
                                & ~ svg{
                                    color: $main_color;
                                }
                            }
                        }
                    }
                    .active{
                        border-bottom-color: $main_color !important;
                        & ~ svg{
                            color: $main_color !important;
                        }
                    }

                    svg {
                        position: absolute;
                        top: 35%;
                        left: 0;
                        font-size: calc($base * 4.6);
                        color: $text_color;
                        transform: translateY(-$border_round);
                        transition: all 0.2s ease;
                    }

                    .button-submit{
                        border: none;
                        border-radius: $base;
                        padding: $gap_sm $gap_md;
                        margin-left: $gap_md;
                        background-color: $button_color;
                        color: $white;
                    }
                }
            }
        }
    }

    .selected-users-wrapper{
        display: flex;
        flex-wrap: wrap;
        gap: $gap_md * 0.8;
        margin: $gap_md 0;
    }
    .user-badge{
        display: flex;
        gap: $gap_sm;
        padding: $base calc($base * 2);
        background-color: $background_color;
        border-radius: $base;

        button{
            @include no_bd_no_bg;
            height: $gap_md;
            width: $gap_md;
            text-align: center;
            font-size: $gap_md;
        }
    }
}

.icon-button{
    @include no_bd_no_bg;
    @include flex_center_center;
    cursor: pointer;
    font-size: $gap_md * 1.5;
    color: $white;
    transition: all 0.3s ease-in;

    &:hover{
        color: $button_color;

        & + .tooltiptext {
            opacity: 0.8;
            transition: opacity 1s;
        }
    }
}

.tooltip{
    position: relative;
}

.tooltiptext {
    position: absolute;
    top: 130%;
    left: 0;
    width: calc($base * 16);
    z-index: 1;
    opacity: 0;
    font-size: calc($base * 2.4);
    text-align: center;
    padding: $base 0;
    margin-left: calc(-#{$base} * 5.2);
    background-color: $button_color;
    color: $white;
    border-radius: $base;

    &::after {
        content: " ";
        position: absolute;
        bottom: 100%; 
        left: $border_round;
        margin-left: -$base;
        border-width: $base;
        border-style: solid;
        border-color: transparent transparent $button_color transparent;
    }
}

.grid{
    grid-template-rows: 70% 30%;
}