@mixin flex_center{
    display: flex;
    align-items: center;
}
@mixin flex_center_center{
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin grid_center_center{
    display: grid;
    align-items: center;
    justify-content: center;
}

@mixin img_md{
    border-radius: $border_round;
    height: $gap_md * 3;
    width: $gap_md * 3;
}

@mixin img_lg {
    border-radius: $border_round;
    height: $gap_lg * 3;
    width: $gap_lg * 3;
}

@mixin no_bd_no_bg{
    border: none;
    background-color: transparent;
}