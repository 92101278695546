$main_color: #B1454A;
$secondary_color: #FFF0DE;
$selection_color: #f7ecec;
$background_color: #E5E5E5;

$button_color: #1F1E31;
$border_color_light: #ccc;

$text_main_color: #121212;
$text_secondary_color: #333333;
$text_color: #888888;

$recieved_message_color: #B4703D;
$sended_message_color: #586e4c;

$white: #fff;
$modal_bg: #00000076;

$gradient: linear-gradient(
  $main_color 0%,
  $main_color 130px,
  $secondary_color 130px,
  $secondary_color 100%
);

$border_round: 50%;

$gap_lg: 2rem;
$gap_md: 1rem;
$gap_sm: 0.5rem;

$base: 5px;