.welcome{
    @include flex_center_center;
    color: $text_main_color;
    flex-direction: column;
    background-color: $background_color;
  
    img {
      height: $gap_lg * 10;
    }
    
    span {
      color: $main_color;
    }
}