.contacts-container{
    .add-group-chat{ 
        .icon-button{
            @include img_md;
            position: absolute;
            top: 90%;
            left: 25%;
            background-color: $button_color;
            font-size: $gap_lg;

            &:hover{
                color: $white;
                font-size: $gap_lg * 1.3;
                transition: font-size 0.5s ease-out;
            }
        }
        .tooltiptext {
            width: $base * 20;
            top: 85%;
            left: 25%;
        
            &::after {
                top: 100%; 
                border-color: $button_color transparent transparent  transparent;
            }
        }
    }
    .contacts {
        .contact {
            .grid-wrapper{
                display: grid;
        
                .chat-name {
                    grid-column: 1 / 2;
                    grid-row: 1;
                }
                .time{
                    grid-column: 3;
                    grid-row: 1;
                    text-align: right;
                    font-size: $gap_md * 0.75;
                    opacity: 0.5;
                }
                .lattest-message{
                    grid-column: 1 / 3;
                    grid-row: 2;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    align-items: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: $gap_md * 0.9;
                    padding-top: $gap_md * 0.8;

                    .content{
                        color: $text_color;
                    }
                    .sender{
                        opacity: 0.9;
                    }
                    .file{
                        color: $main_color;
                        opacity: 0.7;
                    }
                }
            }
        }
        .selected {
          background-color: $selection_color;
        }
    }
}