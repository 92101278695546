.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


@font-face {
    font-family: 'lulo_cleanoutline_bold';
    /* src: url('lulocleanoutlinebold-webfont.woff2') format('woff2'), url('lulocleanoutlinebold-webfont.woff') format('woff'); */
    font-weight: normal;
    font-style: normal;
}

body {

    /* background-color: #aa96da; */
    justify-content: center;
    align-items: center;
    background-image: url("../../assets/padlock-keyhole-icon-personal-data-260nw-1459422656.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right top;
}

h1 {
    /* font-family: 'lulo_cleanoutline_bold'; */
    font-size: 6vw;
    color: #000;
    text-align: center;
}

#word {
    /* font-family: 'lulo_cleanoutline_bold';s */
    font-size: 6vw;
    color: white;
    text-align: center;
}


.content {
    text-align: center;
}

.imagedisplayshow {
    max-width: 300px;
    max-height: 300px;
    display: block;
    margin: 0 auto;
    width: auto;
    height: auto;
}

canvas,
.imagedisplay {
    display: none;
}

.header {
    display: flex;
    /* justify-content: space-around
  ; */
    height: 18%;
    gap: 23rem;
    align-items: center;
    /* background: conic-gradient(from 90deg at 50% 50%, rgba(0, 121, 212, 1) 50%, rgba(32, 111, 180, 1) 60%, rgba(37, 145, 167, 1) 100%); */
}

.bodys {
    width: 100vw;
    height: 100vh;
}


.encode_decode {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 3rem;
}

.allbtn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 3rem;
}


.text_btnup {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* padding-top: 6rem; */
    gap: 2rem;
}



.back_btn {
    width: 5vw;
    height: 50%;
    color: rgb(26, 31, 73);
    border: 1px solid lightseagreen;
    border-radius: 10px;

}

.back_btn_container {
    padding-left: 1rem
}

.back_btn_container:hover {
    box-shadow: 0 20px 50px rgba(8, 112, 184, 0.7);
    transition: trasfrome 0.2s ease;
    scale: 1.1;
}

.Decodedmassage {
    color: rgb(214, 214, 214);
    /* padding: 1rem; */
    max-width: 100%;
    margin-left: 1rem;
}